// import node module libraries
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';



// import requried layout files
import GoToTop from '../GoToTop';



const FooterWithLinks = () => {
	return (
		<footer className="pt-lg-10 pt-5 footer bg-white">
			<Container>
				
				<Row className="align-items-center g-0 border-top py-2 mt-6">
					{/* Desc  */}
					<Col lg={4} md={5} sm={12}>
						<span>© 2025 Softbreak</span>
					</Col>
					{/*  Links  */}
					<Col
						lg={8}
						md={7}
						sm={12}
						className="d-md-flex justify-content-end"
					>
						<nav className="nav nav-footer">
							
							<Link className="nav-link" to="mailto:info@softbreak.net">
							info@softbreak.net
							</Link>
						</nav>
					</Col>
				</Row>
			</Container>
			<GoToTop />
		</footer>
	);
};

export default FooterWithLinks;
