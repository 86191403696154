// ** Import from react dom
import { Route, Routes,Navigate} from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';


/* ----------------------------------- */
import BlankLayout from './BlankLayout';
import NotFound from '../errors/NotFound';
import Error404 from '../errors/Error404';

// Landing menu item pages
import LandingEducation from '../landing/LandingEducation';


const AllRoutes = () => {
	return (
		<Routes>
			{/* Routes with BlankLayout */}
			<Route element={<BlankLayout />}>
				<Route
					path="/"
					element={<LandingEducation />}
				/>
				
			</Route>
 {/* NotFound */}
 <Route element={<NotFound />}>
        <Route path="/404" element={<Error404 />} />
        {/*Redirect*/}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
		</Routes>
	);
};

export default AllRoutes;
