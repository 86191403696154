// eslint-disable-next-line
import { Children } from 'react';
// eslint-disable-next-line
import { v4 as uuid } from 'uuid';

const NavbarDefault = [

	// {
	// 	id: uuid(),
	// 	menuitem: 'Eğitimler',
	// 	link: '#',
	// 	children: [
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'List',
	// 			link: '#'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Grid',
	// 			link: '#'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Single',
	// 			link: '#'
	// 		}
	// 	]
	// },
	// {
	// 	id: uuid(),
	// 	menuitem: 'Hakkımızda',
	// 	link: '#'
	// },
	// {
	// 	id: uuid(),
	// 	menuitem: 'İletişim',
	// 	link: '#'
	// },
];

export default NavbarDefault;
