import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from 'shared/hooks/AppProvider';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<AppProvider>
		<App />
	</AppProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
