// import node module libraries
import React, { Fragment, useEffect } from 'react'


// import custom components
import HeroEducationRightImage from '../ui/HeroEducationRightImage';

// import layouts
import NavbarLanding from '../layouts/Navbars/NavbarLanding';
import FooterCenter from '../layouts/Footers/FooterWithLinks';


const LandingEducation = () => {
  useEffect(() => {
    document.body.className = 'bg-white';
  });

  return (
    <Fragment>
      {/*   Landing Page Navbar */}
      <NavbarLanding center />

      {/*   section  */}
      <main>

        {/*  learn today hero section */}
        <HeroEducationRightImage
 headingPart1="Ezberin Ötesine Geçerek "
 headingPart2="Kurumsal Fark Yaratın"
 description="Yazılım becerilerinizi geliştiriyor, YouTube eğitimlerimiz ve kurumsal firmalar için sunduğumuz özel çözümlerle sizi geleceğin yazılım dünyasına hazırlıyoruz."


  bulletArray={[
    'Üyelere özel detaylı eğitim içerikleri',
    'Projelerle desteklenen pratik öğrenme',
    'Kurumsal yazılım danışmanlığı',
  ]}
  buttonText='Eğitimleri Keşfet'
  buttonLink='https://www.youtube.com/channel/UCzdLcari9TIx-rtWzDNXvdQ/join'
/>


        
      </main>

      {/*   Footer with center alignment */}
      <FooterCenter />

    </Fragment>
  )
}

export default LandingEducation